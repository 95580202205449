import Intercom, { update } from "@intercom/messenger-js-sdk";
import type { InitType } from "@intercom/messenger-js-sdk/dist/types";
import { useOktaAuth } from "@okta/okta-react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import getPublicRuntimeConfig from "../../utils/getPublicRuntimeConfig/getPublicRuntimeConfig";
import { useCurrentUser } from "../useCurrentUser";
import { useIntercomUserHash } from "./useIntercomUserHash";

const { INTERCOM_APP_ID } = getPublicRuntimeConfig();

const INTERCOM_DEFAULTS = {
  // Ensure data is processed in Australia
  api_base: "https://api-iam.au.intercom.io",
  app_id: INTERCOM_APP_ID!, // will only be used when set
  // Hide default chat window
  hide_default_launcher: true,
  region: "ap",
} satisfies InitType;

const useIntercom = () => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated;

  const userEmail = authState?.accessToken?.claims?.sub;
  const userName = authState?.accessToken?.claims?.name;
  const createdAt = useCurrentUser().data?.createdAt;
  const router = useRouter();
  const [isIntercomLoaded, setIntercomLoaded] = useState(false);
  const userHash = useIntercomUserHash();

  useEffect(() => {
    if (!INTERCOM_APP_ID) {
      return;
    }

    Intercom(INTERCOM_DEFAULTS);
    setIntercomLoaded(true);
  }, [isIntercomLoaded]);

  useEffect(() => {
    if (
      !isAuthenticated ||
      !isIntercomLoaded ||
      !userEmail ||
      !userHash ||
      !userName ||
      !INTERCOM_APP_ID ||
      !createdAt
    ) {
      return;
    }

    Intercom({
      ...INTERCOM_DEFAULTS,
      name: userName,
      email: userEmail,
      created_at: new Date(createdAt).getTime(),
      user_hash: userHash,
    });
  }, [
    createdAt,
    isAuthenticated,
    isIntercomLoaded,
    userName,
    userEmail,
    userHash,
  ]);

  useEffect(() => {
    // Disabled in environments without App ID -- local devs
    if (!INTERCOM_APP_ID) {
      return;
    }
    const handleRouteChange = () => {
      update({});
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

export default useIntercom;
